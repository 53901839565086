import jsPDF from 'jspdf'
import { MyFont } from '../static/nav'
import 'jspdf-autotable'

// const PW = 210
// const PH = 297

class PdfGen {
    constructor(format = 'a4', unit = 'mm', orientation = 'portrait') {
        this.doc = new jsPDF({
            format: format,
            unit: unit,
            orientation: orientation,
        });
        this.p = {
            x: 0,
            y: 0,
        }
        this.W = this.doc.internal.pageSize.getWidth()
    }
    initDoc() {
        this.doc.setTextColor(0)
        this.doc.addFileToVFS('AbhayaLibre-Medium-normal.ttf', MyFont);
        this.doc.addFont('AbhayaLibre-Medium-normal.ttf', 'AbhayaLibre-Medium', 'normal');
        this.doc.setFont('AbhayaLibre-Medium')
        this.doc.setFontSize(9);
        return this
    }
    output() {
        return this.doc.output('datauristring')
    }
    print(){
        this.doc.autoPrint()
        return this.doc.output('dataurlnewwindow');
    }
    draw(obj) {
        let startY = 30
        this.__drawHeader('2020/12/24')
        obj.data = JSON.parse(obj.data)
        obj.data = obj.data.map(x=>{
            return {...x,
                cantPrim:(x.cantPrim.toFixed(2)),
                AcPUfTVA:(x.AcPUfTVA.toFixed(2)),
                AcVfTVA:(x.AcVfTVA.toFixed(2)),
                adaos:(x.adaos.toFixed(2)),
                AdPUfTVA:(x.AdPUfTVA.toFixed(2)),
                AdTfTVA:(x.AdTfTVA.toFixed(2)),
                VaPUfTVA:(x.VaPUfTVA.toFixed(2)),
                VaVfTVA:(x.VaVfTVA.toFixed(2)),
                VaPUcTVA:(x.VaPUcTVA.toFixed(2)),
                VaVcTVA:(x.VaVcTVA.toFixed(2)),
            }
        })
        console.log("AAA",obj.data)
        const d = new Date(obj.date)
        startY = this.__drawTable(startY,obj.data, {company:obj.company.companyName, date:`${d.toLocaleDateString('ro-RO')}`,carNr:obj.nrCar, nrDoc:obj.pageId, curier:obj.curier, fac:obj.docIns})
        return this
    }
    __drawHeader(date) {
        this.__drawRect(8, 14, this.W-8, 13, 2)
        this.doc.setFontSize(12)
        this.doc.text('NOTA DE RECEPTIE SI CONSTATARE DE DIFERENTE', this.W / 2, this.p.y + 6+14, 'center')
        this.doc.setFontSize(8)
        this.doc.text('(pentru utilitățile cu amănuntul)', this.W / 2, this.p.y + 9+14, 'center')
        this.doc.setFontSize(9)
        this.doc.text('Unitatea:', 11, 10+14,)
        this.doc.text('S.C. BOTCOM S.R.L', 25, 10+14, {renderingMode:'fillThenStroke'})
        this.doc.text('Magazin Mixt', 25, 13+14, {renderingMode:'fillThenStroke'})
        this.p.y = 12
    }
    __drawTable(startY, data, headerData) {
        this._drawTableHeader(startY,headerData)
        startY +=11
        const t1 = data.reduce((a,b)=>a+parseFloat(b.AcVfTVA),0).toFixed(2)
        const t2 = data.reduce((a,b)=>a+parseFloat(b.AdTfTVA),0).toFixed(2)
        const t3 = data.reduce((a,b)=>a+parseFloat(b.VaVfTVA),0).toFixed(2)
        const t = data.reduce((a,b)=>a+parseFloat(b.VaVcTVA),0).toFixed(2)
        this.doc.autoTable({startY,body:data,...autoTableOptions, foot:[['',{content:'TOTAL', styles:{ halign: 'center' }},'','','','',t1,'','',t2,'',t3,'',t]]})
        startY = this.doc.lastAutoTable.finalY+2
        startY = this._drawTableFooter(startY,data)
        return startY
    }
    _drawTableHeader(startY,headerData){
        const h = 10
        this.doc.rect(10,startY,this.W-12-5,h)
        this.doc.setFontSize(10);
        this.doc
        this.doc.text(`Furnizate de:`,11,startY+3)
        this.doc.text(`${headerData.company}`,33,startY+3, {renderingMode:'fillThenStroke'})
        this.doc.text(`din: ${headerData.date},   Nr. auto: ${headerData.carNr},       delegat: ${headerData.curier}`,100,startY+3)
        this.doc.text(`Documente însoțitoare: FACTURA:`,11,startY+8)
        this.doc.text(`${headerData.fac}`,65,startY+8, {renderingMode:'fillThenStroke'})
        this.doc.text(`Număr document:`,this.W-40,startY-10)
        this.doc.setFontSize(13);
        this.doc.text(`${headerData.nrDoc}`,this.W-45,startY-4, {renderingMode:'fillThenStroke'})
        this.doc.text(`/ ${headerData.date}`,this.W-34,startY-4)
        this.doc.setFontSize(11);
        //this.doc.text(`Data: ${headerData.date}`,this.W-40,startY-2)
        this.doc.setFontSize(10);
    }
    _drawTableFooter(startY, data){
        //this.doc.setLineWidth(1.0); 
        this.doc.setDrawColor(0, 0, 0);
        let t9 = data.filter(x=>x.TVA == 9).reduce((a,b)=>a+parseFloat(b.VaVcTVA),0)
        let t19 =data.filter(x=>x.TVA == 19).reduce((a,b)=>a+parseFloat(b.VaVcTVA),0)
	let t5 =data.filter(x=>x.TVA == 5).reduce((a,b)=>a+parseFloat(b.VaVcTVA),0)
	let t0 =data.filter(x=>x.TVA == 0).reduce((a,b)=>a+parseFloat(b.VaVcTVA),0)
        this.doc.text(`Total 19%:`,this.W-80,startY+2)
        this.doc.text(`Total 9%:`,this.W-80,startY+6)
	this.doc.text(`Total 5%:`,this.W-80,startY+10)
	this.doc.text(`Total 0%:`,this.W-80,startY+14)
        this.doc.setFontSize(13);
        this.doc.text(`Total:`,this.W-45,startY+4, {renderingMode:'fillThenStroke'})
        this.doc.text(`Total 0:`,this.W-45,startY+12, {renderingMode:'fillThenStroke'})
        this.doc.setFontSize(10);
        this.doc.text(`${(t19).toFixed(2)}`,this.W-60,startY+2)
        this.doc.text(`${(t9).toFixed(2)}`,this.W-60,startY+6)
	this.doc.text(`${(t5).toFixed(2)}`,this.W-60,startY+10)
	this.doc.text(`${(t0).toFixed(2)}`,this.W-60,startY+14)
        this.doc.setFontSize(13);
        this.doc.text(`${(t9+t19+t5).toFixed(2)}`,this.W-20,startY+4, {renderingMode:'fillThenStroke'})
        this.doc.text(`${(t0).toFixed(2)}`,this.W-20,startY+12, {renderingMode:'fillThenStroke'})
        this.doc.setFontSize(13);
        this.doc.rect(10,startY-1,this.W-12-5,16)
        this.doc.setFontSize(10);
        this.doc.text("Comisia de receptie",50,startY+2)
        this.doc.text("Numele si prenumele",20,startY+6)
        this.doc.text("Semnatura",76,startY+6)
        this.doc.rect(10,startY+3,50,4)
        this.doc.rect(10,startY+7,50,4)
        this.doc.rect(10,startY+11,50,4)
        this.doc.rect(60,startY+3,50,4)
        this.doc.rect(60,startY+7,50,4)
        this.doc.rect(60,startY+11,50,4)
        return startY +15
    }
    __drawRect(x, y, w, h, border = 2) {
        this.doc.rect(this.p.x + x + border, this.p.y + border + y, w - border * 2-5, h)
    }

}

export const genPdf = (dataObj) => {

    const xx = new PdfGen().initDoc().draw(dataObj).print()


    console.log(dataObj)

    // var string = xx
    // var embed = "<embed width='100%' height='1000%' src='" + string + "'/>"
    // var x = window
    // x.document.open();
    // x.document.write(embed);
    // x.document.close();

}


const autoTableOptions = {
    margin: { top: 18, left: 10, right: 7 },
    headStyles: {
        fillColor: null,
        fontSize: 7,
        textColor: 0,
        lineWidth: 0.1,
        lineColor: 0,
        cellPadding: 1,
    },
    bodyStyles: {
        fillColor: null,
        fontSize: 7,
        textColor: 0,
        lineWidth: 0.1,
        lineColor: 0,
        halign: 'right'
    },
    footStyles: {
        fillColor: null,
        fontSize: 7,
        textColor: 0,
        lineWidth: 0.1,
        lineColor: 0,
        cellPadding: 1,
        halign: 'right',
    },
    styles: {
        maxCellHeight: 4,
        cellPadding: 0.3,
    },
    columnStyles: {
        0: { halign: 'center' },
        1: { halign: 'left' },
        2: { halign: 'center' },
    },
    lineWidth: 0,
    theme: 'grid',
    head: [[{ content: 'Nr. Crt.', rowSpan: 2, styles: { halign: 'left', cellWidth: 7 } },
    { content: 'Denumire produse sau a servicii/Cod', rowSpan: 2, styles: { valign: 'center', halign: 'left' } },
    { content: 'TVA (%)', rowSpan: 2, styles: { valign: 'center', halign: 'center', cellWidth: 7 } },
    { content: 'U.M.', rowSpan: 2, styles: { valign: 'center', halign: 'center', cellWidth: 7 } },
    { content: 'Cant. primita', rowSpan: 2, styles: { valign: 'center', halign: 'center', cellWidth: 10 } },

    { content: 'Achizitie', colSpan: 2, styles: { halign: 'center' } },
    { content: 'Adaos', colSpan: 3, styles: { halign: 'center' } },
    { content: 'Vânzare', colSpan: 4, styles: { halign: 'center' } }],
    [{ content: 'P. U.\n (fara TVA)', styles: { cellWidth: 10 } },
    { content: 'Valoare\n (fara TVA)', styles: { cellWidth: 11 } },
    { content: 'Adaos', styles: { cellWidth: 10 }},
    { content: 'P. U.\n (fara TVA)', styles: { cellWidth: 10 }  },
    { content: 'Total\n (fara TVA)', styles: { cellWidth: 10 }  },
    { content: 'P. U.\n (fara TVA)', styles:{ cellWidth: 11 }},
    { content: 'Total\n (fara TVA)', styles:{ cellWidth: 11 }},
    { content: 'P. U.\n (cu TVA)', styles: { cellWidth: 11 } },
    { content: 'Total\n (cu TVA)', styles: { cellWidth: 15 } },
    ]],
    columns:[
        {dataKey: 'id'},
        {dataKey:'name'},
        {dataKey:'TVA'},
        {dataKey:'um'},
        {dataKey:'cantPrim'},
        {dataKey:'AcPUfTVA'},
        {dataKey:'AcVfTVA'},
        {dataKey:'adaos'},
        {dataKey:'AdPUfTVA'},
        {dataKey:'AdTfTVA'},
        {dataKey:'VaPUfTVA'},
        {dataKey:'VaVfTVA'},
        {dataKey:'VaPUcTVA'},
        {dataKey:'VaVcTVA'},
    ]
}
